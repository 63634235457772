import * as React from "react";
import { graphql, Link } from "gatsby";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import Header from "../components/Header";
import { ThemeProvider } from "@mui/material/styles";
import HeroBanner from "../images/hero_banner.svg";
import "../styles/layout.css";
import theme from "../config/theme";
import CategoryList from "../components/CategoryList";
import { Container, Box, Typography, TextField } from "@mui/material";
// data

// markup
const IndexPage = props => {
    const { t } = useTranslation();
    console.log(props);
    return (
        <ThemeProvider theme={theme}>
            <Container disableGutters maxWidth={false}>
                <Header props={props} showMenu={false} />
                <Box sx={{ flexGrow: 1, backgroundColor: "#6A52C2", width: "100%", height: 350, position: "relative" }} elevation={0} square={true}>
                    <Box
                        sx={{
                            backgroundImage: `url(${props.data.homepage.hero.hero_banner.localFile.publicURL})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            opacity: 0.2,
                        }}
                        elevation={0}
                        square={true}
                    ></Box>
                    <Box
                        sx={{
                            height: "100%",
                            background: "transparent",
                            width: "100%",
                            position: "absolute",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 2,
                            color: "white",
                            flexDirection: "column",
                        }}
                    >
                        <Typography sx={{ fontSize: "h3.fontSize" }}>
                            <Trans>user_guide</Trans>
                        </Typography>
                        <Typography>
                            <Trans>solve_common_questions_about_system_operation</Trans>
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: "100%",
                        textAlign: "center",
                        my: 6,
                        zIndex: 3,
                    }}
                >
                    <Typography sx={{ fontSize: "h5.fontSize" }}>
                        <Trans>need_help_weve_got_your_back</Trans>
                    </Typography>
                    <Typography>
                        <Trans>from_account_settings_to_permissions_find_help_for_everything_storeberry</Trans>{" "}
                    </Typography>
                    <Typography>
                        <Trans>if_youre_new_to_storeberry_and_looking_for_tips_check_out_our_beginners_guide</Trans>{" "}
                    </Typography>
                </Box>
                <Container sx={{ paddingBottom: 20 }}>
                    <CategoryList
                        showBreadcrumb={true}
                        categories={
                            props.data.categories
                                ? props.data.categories.edges.map(({ node }) => ({
                                      id: node.id,
                                      locale: node.locale,
                                      name: node.name,
                                      slug: node.slug,
                                      image: node.image,
                                      order: node.order,
                                      count: node.subcategories.length,
                                      subcategories: node.subcategories.sort(function (a, b) {
                                          return b.order - a.order;
                                      }),
                                  }))
                                : []
                        }
                    ></CategoryList>
                </Container>
            </Container>
        </ThemeProvider>
    );
};

export default IndexPage;

export const query = graphql`
    query ($language: String!, $locale: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        homepage: strapiHomepage {
            hero {
                id
                title
                hero_banner {
                    localFile {
                        publicURL
                    }
                }
            }
        }
        articles: allStrapiArticle(filter: { locale: { eq: $locale } }) {
            edges {
                node {
                    id
                    slug
                    title
                    locale
                }
            }
        }
        categories: allStrapiCategory(sort: { fields: order, order: ASC }, filter: { locale: { eq: $locale } }) {
            edges {
                node {
                    id
                    name
                    slug
                    locale
                    order
                    subcategories {
                        id
                        name
                        slug
                        order
                        locale
                    }
                    image {
                        localFile {
                            publicURL
                        }
                    }
                }
            }
        }
    }
`;
